import React, { useEffect, useState, useContext, useCallback } from 'react';
import * as S from './TagSelector.styles';
import FunnelContext from '../../context/FunnelContext';

const TagSelector = props => {
  const { styles = 'default', container, children } = props;

  const {
    extraObjects,
    setExtraObjects,
    funnelUpdated,
    setFunnelUpdated,
    setTubIndex: setTubIndexContext,
    setCurrentTubIndex: setCurrentTubIndexContext,
    setCurrentProduct,
    currentCategory,
    answers: answersContext,
    setAnswers,
    tag: tagContext,
    setTag
  } = useContext(FunnelContext);

  const [loaded, setLoaded] = useState(false);
  const [selected, setSelected] = useState('');

  const productPrefix =
    currentCategory === `onetime` ? `onetime` : `subscription`;

  const handleClick = useCallback(
    ({
      tag,
      setCurrentTubIndex = false,
      currentTubIndex = 0,
      setRecommendedTub = false,
      recommendedTubIndex = 0,
      recommendedTubText = '1 Tub (Recommended)',
      answers
    }) => {
      setSelected(tag);
      setTag(tag);
      setExtraObjects(
        Object.assign(extraObjects, {
          filterProductItemsByTag: tag
        })
      );
      if (setCurrentTubIndex) {
        setTubIndexContext(currentTubIndex);
        setCurrentTubIndexContext(currentTubIndex);
        setCurrentProduct(`${productPrefix}_${currentTubIndex}`);
      }
      if (setRecommendedTub) {
        Object.assign(extraObjects, {
          setRecommendedTub,
          recommendedTubIndex,
          recommendedTubText
        });
      }
      if (answers) {
        answers.forEach(({ key, value }) => {
          if (key) {
            const newAnswers = Object.assign(answersContext, {
              [key]: value
            });
            setAnswers(newAnswers);
          }
        });
      }
      setFunnelUpdated(!funnelUpdated);
    },
    [
      funnelUpdated,
      setFunnelUpdated,
      setSelected,
      setTag,
      extraObjects,
      setExtraObjects,
      setTubIndexContext,
      setCurrentTubIndexContext,
      productPrefix,
      setCurrentProduct,
      answersContext,
      setAnswers
    ]
  );

  useEffect(() => {
    if (tagContext !== selected) {
      handleClick({ tag: tagContext });
    }
  }, [selected, tagContext, handleClick]);

  useEffect(() => {
    const defaultTag = container.find(c => c.default);
    if (!loaded && defaultTag && tagContext === '') {
      handleClick(defaultTag);
      setLoaded(true);
    }
  }, [container, tagContext, setSelected, loaded, setLoaded, handleClick]);

  if (!container) return null;
  if (!children) return null;

  return container.map((cont, index) => (
    <S.Container
      key={index}
      selected={selected === cont.tag}
      onClick={() => handleClick(cont)}
      className={styles}
    >
      {children[index]}
    </S.Container>
  ));
};

export default TagSelector;
