import styled from 'styled-components';

export const Container = styled.div`
  width: calc(100% - 10px);
  cursor: pointer;
  transition: all 2s;
  &:hover > div {
    border: 1px solid #bc2e3e;
    background-color: #f8eaec;
  }

  &.pdp2023:hover > div {
    border: 2px solid #cd0053;
    background-color: #fae5ee;
    z-index: 1;
    position: relative;
  }

  &.blue > div {
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  &.blue:hover > div {
    border: 2px solid #001c72;
    background-color: #fff;
  }

  ${({ selected }) =>
    selected
      ? `
        & > div {
          border: 1px solid #BC2E3E!important;
          background-color: #F8EAEC;
        }
        &.pdp2023 > div {
          border: 2px solid #CD0053!important;
          background-color: #FAE5EE;
        }

        &.blue > div {
          border: 2px solid #001C72!important;
          background: radial-gradient(344.85% 141.42% at 100.00% 100.00%, #002084 0%, #001C72 100%);
          color: #FFF!important;
        }
    `
      : ``}
`;
